import { useEffect, useState, useContext } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ActionSelector } from "./ActionSelector";
import { ActionContext } from "./ActionContext";
import { ActionSummary } from './ActionSummary';
import { TriggerButton } from './TriggerButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faRedditAlien, faFacebook, faInstagram, faChrome, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const actionIcons = {
  "Gmail Automation": faGoogle,
  "Reddit Automation": faRedditAlien,
  "Facebook Automation": faFacebook,
  "Instagram Automation": faInstagram,
  "Chrome Automation": faChrome,
  "TikTok Automation": faTiktok
};

export function DeviceTable() {
  const { selectedActions } = useContext(ActionContext);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deviceActions, setDeviceActions] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    async function fetchDevices() {
      try {
        const response = await fetch("http://80.211.121.8:8000/device/list_devices");
        const data = await response.json();
        setDevices(data);
      } catch (error) {
        console.error("Failed to fetch devices:", error);
      }
    }

    fetchDevices();

    const ws = new WebSocket("ws://80.211.121.8:8000/ws/general");

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "accessibility_update") {
        setDevices((prevDevices) =>
          prevDevices.map((device) =>
            device.uuid === message.uuid
              ? { ...device, accessibility: message.accessibility }
              : device
          )
        );
      } else if (message.type === "new_device") {
        setDevices((prevDevices) => [...prevDevices, message.device]);
      }
    };

    return () => {
      ws.close();
    };
  }, []);

  const handleDeviceSelection = (uuid, checked) => {
    let updatedSelectedDevices;
    let updatedDeviceActions = { ...deviceActions };
    if (checked) {
      updatedSelectedDevices = [...selectedDevices, uuid];
      updatedDeviceActions[uuid] = selectedActions;
    } else {
      updatedSelectedDevices = selectedDevices.filter(id => id !== uuid);
      delete updatedDeviceActions[uuid];
    }
    setSelectedDevices(updatedSelectedDevices);
    setDeviceActions(updatedDeviceActions);

    console.log("Applying actions:", selectedActions, "to devices:", updatedSelectedDevices);
  };

  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    let updatedSelectedDevices;
    let updatedDeviceActions = { ...deviceActions };
    if (checked) {
      updatedSelectedDevices = devices
        .filter(device => device.accessibility)
        .map(device => device.uuid);
      updatedSelectedDevices.forEach(deviceId => {
        updatedDeviceActions[deviceId] = selectedActions;
      });
    } else {
      updatedSelectedDevices = [];
      updatedDeviceActions = {};
    }
    setSelectedDevices(updatedSelectedDevices);
    setDeviceActions(updatedDeviceActions);

    console.log("Applying actions:", selectedActions, "to devices:", updatedSelectedDevices);
  };

  const handleResetActionsForDevice = (deviceId) => {
    setDeviceActions((prevDeviceActions) => {
      const updatedDeviceActions = { ...prevDeviceActions };
      delete updatedDeviceActions[deviceId];
      return updatedDeviceActions;
    });
  };

  const hasSelectedActions = Object.keys(selectedActions).length > 0;

  return (
    <div className="overflow-x-auto">
      <div className="mb-4">
        <ActionSelector />
      </div>
      <ActionSummary />

      {selectedDevices.length > 0 && hasSelectedActions && (
        <div className="mb-4">
          <TriggerButton selectedDevices={selectedDevices} devices={devices} setErrorMessages={setErrorMessages} />
        </div>
      )}
      
      {!hasSelectedActions && (
        <div className="mb-4 text-red-500">
          Please select actions first before selecting devices.
        </div>
      )}

      <table className="min-w-full bg-gray-800 text-white">
        <thead>
          <tr>
            <th className="p-4">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={(e) => handleSelectAll(e.target.checked)}
                disabled={!hasSelectedActions}
              />
              <span className="ml-2">Select All</span>
            </th>
            <th className="p-4 text-left">Device Name</th>
            <th className="p-4 text-left">Device no#</th>
            <th className="p-4 text-left">Applied Actions</th>
            <th className="p-4 text-left">Accessibility Status</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device) => (
            <tr
              key={device.uuid}
              className={`bg-gray-700 border-b border-gray-600 ${!device.accessibility && "opacity-50"}`}
              title={!device.accessibility ? "Accessibility disabled" : ""}
            >
              <td className="p-4">
                <input
                  type="checkbox"
                  disabled={!device.accessibility || !hasSelectedActions}
                  checked={selectedDevices.includes(device.uuid)}
                  onChange={(e) => handleDeviceSelection(device.uuid, e.target.checked)}
                />
              </td>
              <td className="p-4 font-medium">
                {device.device_name}
              </td>
              <td className="p-4">{device.alias}</td>
              <td className="p-4">
                {deviceActions[device.uuid]
                  ? (
                    <>
                      {Object.entries(deviceActions[device.uuid]).map(([action, subActions]) => (
                        <div key={action}>
                          {subActions.map((subAction, index) => (
                            <span key={index} className="mr-2">
                              <FontAwesomeIcon icon={actionIcons[action]} /> | {subAction}
                            </span>
                          ))}
                        </div>
                      ))}
                      <button
                        onClick={() => handleResetActionsForDevice(device.uuid)}
                        className="mt-2 px-2 py-1 text-sm bg-red-500 text-white rounded"
                      >
                        Reset Actions
                      </button>
                    </>
                  )
                  : 'No actions applied'}
                {errorMessages[device.uuid] && (
                  <div className="text-red-500 mt-2">
                    {errorMessages[device.uuid].map((error, index) => (
                      <div key={index}><FontAwesomeIcon icon={faExclamationCircle} /> {error}</div>
                    ))}
                  </div>
                )}
              </td>
              <td className="p-4">
                <div className="relative group">
                  {device.accessibility ? (
                    <i className="fas fa-check-circle text-green-500"></i>
                  ) : (
                    <i className="fas fa-times-circle text-red-500"></i>
                  )}
                  <div className="absolute hidden group-hover:block bg-gray-700 text-white text-xs rounded p-2 mt-1">
                    {device.accessibility ? 'Accessibility enabled' : 'Accessibility disabled'}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
