import { useContext } from "react";
import { ActionContext } from "./ActionContext";

export function ActionSummary() {
  const { selectedActions, setSelectedActions } = useContext(ActionContext);

  const handleResetActions = () => {
    setSelectedActions({});
  };

  const hasSelectedActions = Object.keys(selectedActions).length > 0;

  return (
    <div className="mb-4 p-4 bg-gray-700 rounded-lg relative">
      <h2 className="text-lg font-semibold mb-2">Selected Actions</h2>
      {hasSelectedActions ? (
        <>
          <button
            onClick={handleResetActions}
            className="absolute top-4 right-4 px-4 py-2 bg-red-500 text-white rounded-lg"
          >
            Reset Actions
          </button>
          {Object.entries(selectedActions).map(([action, subActions]) => (
            <div key={action}>
              <strong>{action}:</strong> {subActions.join(', ')}
            </div>
          ))}
        </>
      ) : (
        <p>No actions selected.</p>
      )}
    </div>
  );
}
