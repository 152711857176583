import { useState, useContext } from "react";
import { ActionContext } from "./ActionContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faRedditAlien, faFacebook, faInstagram, faChrome, faTiktok } from '@fortawesome/free-brands-svg-icons';

const actions = [
  {
    name: "Gmail Automation",
    sub_actions: ["Setup", "Check Inbox"],
    icon: faGoogle
  },
  {
    name: "Reddit Automation",
    sub_actions: ["Setup", "Upvote", "Comment"],
    icon: faRedditAlien
  },
  {
    name: "Facebook Automation",
    sub_actions: ["Setup", "Like Post", "Comment"],
    icon: faFacebook
  },
  {
    name: "Instagram Automation",
    sub_actions: ["Setup", "Like Post", "Comment"],
    icon: faInstagram
  },
  {
    name: "Chrome Automation",
    sub_actions: ["Setup", "Open Tab", "Browse"],
    icon: faChrome
  },
  {
    name: "TikTok Automation",
    sub_actions: ["Setup", "Like", "Comment"],
    icon: faTiktok
  }
];

function Modal({ isOpen, onClose, onSave }) {
  const [postLink, setPostLink] = useState("");

  const handleSave = () => {
    onSave(postLink);
    setPostLink("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-gray-800 p-4 rounded shadow-lg text-white">
        <h2 className="text-lg font-semibold mb-2">Enter Post Link</h2>
        <input
          type="text"
          value={postLink}
          onChange={(e) => setPostLink(e.target.value)}
          className="border p-2 w-full mb-2 text-black"
        />
        <div className="flex justify-end">
          <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-500 text-white rounded">Cancel</button>
          <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">Save</button>
        </div>
      </div>
    </div>
  );
}

export function ActionSelector() {
  const { selectedActions, setSelectedActions } = useContext(ActionContext);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentSubAction, setCurrentSubAction] = useState(null);
  const [notification, setNotification] = useState("");

  const handleActionChange = (action, subAction, checked) => {
    const updatedActions = {};

    if (checked) {
      updatedActions[action] = [subAction];
      if (subAction === "Upvote") {
        setCurrentAction(action);
        setCurrentSubAction(subAction);
        setIsModalOpen(true);
      }
    }

    setSelectedActions(updatedActions);
    setNotification(""); // Clear notification
  };

  const handleModalSave = (postLink) => {
    const updatedActions = { ...selectedActions };
    if (updatedActions[currentAction]) {
      const index = updatedActions[currentAction].indexOf(currentSubAction);
      if (index !== -1) {
        updatedActions[currentAction][index] = `${currentSubAction} (${postLink})`;
      }
    }
    setSelectedActions(updatedActions);
    setIsModalOpen(false);
  };

  const toggleDropdown = (actionName) => {
    setActiveDropdown((prev) => (prev === actionName ? null : actionName));
  };

  return (
    <div className="flex flex-wrap justify-center">
      <div className="w-full">
        <h2 className="text-lg font-semibold mb-2 text-center">Select Actions</h2>
        {notification && (
          <div className="bg-red-500 text-white p-2 rounded mb-2 text-center">
            {notification}
          </div>
        )}
      </div>
      {actions.map((action) => (
        <div key={action.name} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 p-1">
          <div className="relative">
            <button
              onClick={() => toggleDropdown(action.name)}
              className="w-full text-left px-3 py-2 mb-2 text-sm font-medium bg-gray-700 text-white hover:bg-gray-600 rounded-lg flex items-center"
            >
              <FontAwesomeIcon icon={action.icon} className="mr-2" />
              <span className="flex-1">{action.name}</span>
            </button>
            {activeDropdown === action.name && (
              <div className="absolute z-10 bg-gray-800 rounded-lg shadow-md mt-2 w-full">
                {action.sub_actions.map((subAction) => (
                  <div key={subAction} className="flex items-center p-2 hover:bg-gray-700">
                    <input
                      type="checkbox"
                      id={`${action.name}-${subAction}`}
                      name={subAction}
                      onChange={(e) => handleActionChange(action.name, subAction, e.target.checked)}
                      checked={selectedActions[action] && selectedActions[action].includes(subAction)}
                      className="mr-2"
                    />
                    <label htmlFor={`${action.name}-${subAction}`} className="text-sm">{subAction}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleModalSave}
      />
    </div>
  );
}
