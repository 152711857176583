import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ActionProvider } from './components/ActionContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ActionProvider>
    <App />
  </ActionProvider>
);
