import { createContext, useState } from "react";

export const ActionContext = createContext();

export function ActionProvider({ children }) {
  const [selectedActions, setSelectedActions] = useState({});

  return (
    <ActionContext.Provider value={{ selectedActions, setSelectedActions }}>
      {children}
    </ActionContext.Provider>
  );
}
