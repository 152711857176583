import { useContext, useState } from "react";
import { ActionContext } from "./ActionContext";

export function TriggerButton({ selectedDevices, devices, setErrorMessages }) {
  const { selectedActions } = useContext(ActionContext);
  const [message, setMessage] = useState("");

  const checkPrerequisites = (device, action, subAction) => {
    const statuses = device.statuses;
    if (action !== "Gmail Automation" && !statuses.gmail_app_setup_completed) {
      return "Gmail setup is required";
    }
    if (subAction.includes("Upvote") && !statuses.reddit_app_setup_completed) {
      return "Reddit setup is required for Upvote";
    }
    return null;
  };

  const triggerAutomation = async () => {
    let errors = {};

    for (const deviceId of selectedDevices) {
      const device = devices.find(d => d.uuid === deviceId);
      for (const [action, subActions] of Object.entries(selectedActions)) {
        for (const subAction of subActions) {
          const prerequisiteError = checkPrerequisites(device, action, subAction);
          if (prerequisiteError) {
            if (!errors[deviceId]) {
              errors[deviceId] = [];
            }
            errors[deviceId].push(prerequisiteError);
          } else {
            let endpoint = "";
            let body = null;
            switch (action) {
              case "Gmail Automation":
                endpoint = `/trigger_gmail_setup/${deviceId}`;
                break;
              case "Reddit Automation":
                if (subAction.startsWith("Setup")) {
                  endpoint = `/trigger_reddit_setup/${deviceId}`;
                } else if (subAction.startsWith("Upvote")) {
                  endpoint = `/trigger_reddit_upvote/${deviceId}`;
                  const link = subAction.match(/\(([^)]+)\)/)?.[1];
                  body = { link }; // Include the link for Reddit Upvote
                }
                break;
              case "Facebook Automation":
                endpoint = `/trigger_facebook_setup/${deviceId}`;
                break;
              case "Instagram Automation":
                endpoint = `/trigger_instagram_setup/${deviceId}`;
                break;
              case "Chrome Automation":
                endpoint = `/trigger_google_chrome_setup/${deviceId}`;
                break;
              case "TikTok Automation":
                endpoint = `/trigger_tiktok_setup/${deviceId}`;
                break;
              default:
                console.error(`Unknown action: ${action}`);
                continue;
            }
            await fetch(`http://80.211.121.8:8000/automation${endpoint}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: body ? JSON.stringify(body) : null
            });
          }
        }
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
    } else {
      setMessage("Automation commands sent!");
      setErrorMessages({});
    }
  };

  return (
    <div>
      <button
        onClick={triggerAutomation}
        className="mb-4 px-4 py-2 bg-green-500 text-white rounded-lg"
      >
        Trigger Automation
      </button>
      {message && <p className="text-green-500">{message}</p>}
    </div>
  );
}
