import './App.css';
import { NavBar } from './components/NavBar';
import { DeviceTable } from './components/DeviceTable';
import { ActionProvider } from './components/ActionContext';

function App() {
  return (
    <ActionProvider>
      <div className="App min-h-screen bg-gray-900 text-white">
        <NavBar />
        <div className="container mx-auto mt-8 p-4 bg-gray-800 rounded-lg shadow-lg">
          <DeviceTable />
        </div>
      </div>
    </ActionProvider>
  );
}

export default App;
